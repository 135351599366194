// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    var ConfiguratorMobile;
    ConfiguratorMobile = (function() {
      var getCookie, self, setCookie;

      self = false;

      function ConfiguratorMobile() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
        self.settings = {
          headerTag: 'h2',
          bodyTag: 'section',
          contentContainerTag: 'div',
          actionContainerTag: 'div',
          stepsContainerTag: 'div',
          cssClass: 'wizard',
          loadingTemplate: '<span class="spinner"></span> #text#',
          autoFocus: true,
          enableAllSteps: false,
          enableKeyNavigation: false,
          enablePagination: false,
          suppressPaginationOnFocus: true,
          enableContentCache: true,
          enableCancelButton: true,
          enableFinishButton: true,
          preloadContent: false,
          showFinishButtonAlways: false,
          forceMoveForward: false,
          saveState: false,
          startIndex: 0,
          transitionEffect: 'slideLeft',
          transitionEffectSpeed: 200,
          onStepChanged: function() {
            return self.onStepChangedFunctions();
          },
          labels: {
            cancel: 'Cancel',
            current: 'current step:',
            pagination: 'Pagination',
            finish: 'Finish',
            next: 'Next',
            previous: 'Previous',
            loading: 'Loading ...'
          }
        };
      }

      ConfiguratorMobile.prototype.isRunnable = function() {
        return $('#configuratore-piatto-unico-mobile').length > 0 && $(w).width() < 768;
      };

      ConfiguratorMobile.prototype.ready = function() {
        self = this;
        $.extend($.expr[':'], {
          'containsIN': function(elem, i, match, array) {
            return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0;
          }
        });
        self.wizard = $('#cpu-mobile');
        return $(w).load(function() {
          if (self.isRunnable()) {
            self.bindings();
            return self.load();
          }
        });
      };

      ConfiguratorMobile.prototype.load = function() {
        self.wizard.steps(self.settings);
        w.setTimeout(function() {
          self.hideLoading();
        }, 1500);
      };

      ConfiguratorMobile.prototype.getActiveFilters = function() {
        return $('.wrap-ingredients-list .container-search .filters .filter.active');
      };

      ConfiguratorMobile.prototype.getActiveFiltersValue = function() {
        var activeValues;
        activeValues = [];
        $.each(self.getActiveFilters(), function(index, filter) {
          activeValues.push($(filter).attr('data-value'));
        });
        return activeValues;
      };

      ConfiguratorMobile.prototype.bindings = function() {
        $('#cpu-mobile').on("keyup", ".container-search input.search-ingredients", function(event) {
          var activeValues, textValue;
          textValue = $(this).val();
          activeValues = self.getActiveFiltersValue();
          self.filterRicette(textValue.toLowerCase(), activeValues);
        });
        $('#cpu-mobile').on("click", ".post-ingredients a.ingredient", function(event) {
          return self.saveIngredient($(this));
        });
        $('#cpu-mobile').on("click", ".wrap-ingredients-list .container-search .filters .filter", function(event) {
          var activeValues;
          $(this).toggleClass('active');
          activeValues = self.getActiveFiltersValue();
          self.filterRicette($('.container-search input.search-ingredients').val(), activeValues);
        });
      };

      ConfiguratorMobile.prototype.viewRicette = function(elem) {
        var currentStepIndex;
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        self.showIngredients(elem);
        self.wizard.steps('insert', currentStepIndex + 1, {
          title: $(elem).attr('data-name'),
          content: '<div class="wrap-ingredients-list">' + $('.wrap-ingredients-list').html() + '</div>'
        });
        self.nextStep();
        return self.wizard.attr('data-step-remove', currentStepIndex + 1);
      };

      ConfiguratorMobile.prototype.showIngredients = function(elem) {
        var child, parent, postFiltered;
        parent = $(elem).data("parent");
        child = $(elem).data("children");
        $('.post-ingredients li').css('clear', 'none').removeClass('active').hide();
        postFiltered = $('.post-ingredients li').filter(function() {
          return $.inArray(child.toString(), $(this).data('tipologia-id').toString().split(',')) !== -1;
        });
        postFiltered.show().addClass('active');
        return $('.post-ingredients li.active').filter(':even').css('clear', 'both');
      };

      ConfiguratorMobile.prototype.getRecipePdf = function() {
        var carboId, proteineId, sendData, url, verdureId;
        url = $('#form-create-dish-mobile').attr('action');
        proteineId = $('#form-create-dish-mobile input[name="proteine"]').val();
        carboId = $('#form-create-dish-mobile input[name="carboidrati"]').val();
        verdureId = $('#form-create-dish-mobile input[name="verdure"]').val();
        sendData = {
          'action': 'get_recipes_pdf',
          'proteine_id': proteineId,
          'carboidrati_id': carboId,
          'verdure_id': verdureId,
          'is_ajax': 1
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: sendData,
          success: function(data) {
            var pdflist;
            pdflist = [];
            if (data.proteine_pdf !== void 0 && data.proteine_pdf !== false) {
              pdflist.push(data.proteine_pdf);
            }
            if (data.carboidrati_pdf !== void 0 && data.carboidrati_pdf !== false) {
              pdflist.push(data.carboidrati_pdf);
            }
            if (data.verdure_pdf !== void 0 && data.verdure_pdf !== false) {
              pdflist.push(data.verdure_pdf);
            }
            self.openLinkList(pdflist);
          }
        });
      };

      ConfiguratorMobile.prototype.openLinkList = function(arr) {
        return $.each(arr, function(key, link) {
          if (link !== '') {
            return w.open(link);
          }
        });
      };

      ConfiguratorMobile.prototype.saveIngredient = function(element) {
        var idIngredient, img, type;
        if (element.children('img').hasClass('b-loaded')) {
          img = element.children('img').attr("src");
        } else {
          img = element.children('img').attr("data-src");
        }
        idIngredient = element.data("ingredient-id");
        type = element.data("parent-slug");
        self.updateImageToDish(img, type, idIngredient);
        self.nextStep();
        self.updateProgressBar();
        return $('html,body').stop(false, true).animate({
          scrollTop: 0
        }, 'fast');
      };

      ConfiguratorMobile.prototype.updateImageToDish = function(imgSrc, type, id) {
        var $contImgElem, $imgElement;
        $('#piatto-mobile .slice[data-term-slug="' + type + '"]').addClass('fill');
        if ($('#piatto-mobile .slice[data-term-slug="' + type + '"] .background-img-dish').length === 0) {
          $contImgElem = $('<div/>').addClass('background-img-dish').appendTo($('#piatto-mobile .slice[data-term-slug="' + type + '"]'));
          $imgElement = $('<img/>').appendTo($contImgElem);
        } else {
          $imgElement = $('#piatto-mobile .slice[data-term-slug="' + type + '"] .background-img-dish img');
        }
        $imgElement.attr('src', imgSrc);
        $('#piatto-mobile .slice[data-term-slug="' + type + '"] input').val(id);
        $('#form-download-recipes-mobile span[data-term-slug="' + type + '"] input').val(id);
        return $('#piatto-mobile .slice[data-term-slug="' + type + '"] .text-container').hide();
      };

      ConfiguratorMobile.prototype.filterRicette = function(text, filtersValue) {
        var countMatch;
        if (filtersValue == null) {
          filtersValue = [];
        }
        if (text !== '' || filtersValue.length > 0) {
          $('.post-ingredients li.active').addClass('filtered');
        } else {
          $('.post-ingredients li.active').removeClass('filtered');
        }
        countMatch = 0;
        if (filtersValue.length > 0) {
          $('.post-ingredients li.active').each(function() {
            if ($(this).attr('data-preparation-time') !== '' && $.inArray($(this).attr('data-preparation-time'), filtersValue) >= 0) {
              $(this).removeClass('filtered');
              countMatch++;
            }
          });
        }
        if (text !== '') {
          $('.post-ingredients li.active a.name:containsIN(' + text + ')').each(function() {
            $(this).parents('li').removeClass('filtered');
            countMatch++;
          });
        }
        if ((text !== '' || filtersValue.length > 0) && countMatch === 0) {
          $('.post-ingredients').hide();
          $('.wrap-ingredients-list .text-no-results').show();
        } else {
          $('.wrap-ingredients-list .text-no-results').hide();
          $('.post-ingredients').show();
        }
        $('.post-ingredients li.active').not('.filtered').css('clear', 'none');
        w.bLazy.revalidate();
        return self.adaptHeightStep();
      };

      ConfiguratorMobile.prototype.saveDish = function() {
        var canSave, dishData, isGuest, j, len, method, obj, url;
        $('#form-create-dish-mobile .error-save-dish-already-exist').hide();
        if ($('#piatto-mobile .dish-name input').val() === '') {
          $('#piatto-mobile .dish-name input').focus().addClass("require");
          return;
        } else {
          $('#piatto-mobile .dish-name input').removeClass("require");
        }
        canSave = true;
        $('#piatto-mobile .circle input').each(function() {
          if ($(this).val() === '') {
            canSave = false;
          }
        });
        if (!canSave) {
          $('#form-create-dish-mobile .error-save-dish').show();
          return false;
        } else {
          $('#form-create-dish-mobile .error-save-dish').hide();
        }
        url = $('#form-create-dish-mobile').attr('action');
        method = $('#form-create-dish-mobile').attr('method');
        self.showLoading();
        dishData = $('#form-create-dish-mobile').serializeArray();
        isGuest = false;
        for (j = 0, len = dishData.length; j < len; j++) {
          obj = dishData[j];
          if (obj.name === 'user_id' && obj.value === '0') {
            isGuest = true;
          }
        }
        dishData = $('#form-create-dish').serialize();
        $.ajax({
          type: method,
          url: url,
          dataType: "json",
          data: $('#form-create-dish-mobile').serialize(),
          success: function(data) {
            if (isGuest) {
              setCookie("casadivitaSavedDish", $('#form-create-dish-mobile').serialize());
            }
            if (!data.dish_id || data.dish_id === 0) {
              self.hideLoading();
              $('#form-create-dish-mobile .error-save-dish-already-exist').show();
              return;
            } else {
              self.hideLoading();
              self.nextStep();
              self.updateProgressBar();
            }
            $.each(data.message, function(key, message) {
              return console.debug(message);
            });
          },
          error: function(data) {}
        });
      };

      ConfiguratorMobile.prototype.adaptHeightStep = function() {
        var currentStepIndex, stepHeight;
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        stepHeight = parseInt($('#cpu-mobile-p-' + currentStepIndex).height()) + parseInt($('#cpu-mobile .status-bar').height() + 20);
        stepHeight = Math.max(stepHeight, 350);
        return $('#configuratore-piatto-unico-mobile #cpu-mobile>.content').height(stepHeight + 'px');
      };

      ConfiguratorMobile.prototype.nextStep = function() {
        self.wizard.steps('next');
        $('#cpu-mobile .status-bar').show();
        $('.wrap-menu-inner').hide();
        $('#configuratore-piatto-unico-mobile .restart-configurator-button').show();
        self.removeStep();
        self.changeDishIcon();
        self.adaptHeightStep();
        if (w.bLazy !== void 0) {
          return w.bLazy.revalidate();
        }
      };

      ConfiguratorMobile.prototype.saveAsLogged = function() {
        self.wizard.steps("next");
        return self.disposeMobileRegister();
      };

      ConfiguratorMobile.prototype.disposeMobileRegister = function() {
        $("#mobile-user-wrap").html($("#desktop-user-wrap").html());
        $("#cpu-mobile > div.content.clearfix").height(1.3 * $("#mobile-user-wrap").height());
        window.AjaxLoginInit();
      };

      ConfiguratorMobile.prototype.prevStep = function() {
        self.wizard.steps('previous');
        self.removeStep();
        self.changeDishIcon();
        self.updateProgressBar();
        return self.adaptHeightStep();
      };

      ConfiguratorMobile.prototype.updateProgressBar = function() {
        var barWidth, currentStepIndex, progressBarWidth;
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        progressBarWidth = Math.floor($('#cpu-mobile .status-bar .bar-background').width());
        barWidth = Math.ceil((progressBarWidth / 3) * (currentStepIndex - 1)) + 1;
        return $('#cpu-mobile .status-bar .inner-bar').css({
          'width': barWidth + 'px'
        });
      };

      ConfiguratorMobile.prototype.changeDishIcon = function() {
        var currentStepIndex, iconDish;
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        iconDish = $('#cpu-mobile-p-' + currentStepIndex + ' .icon-dish').attr('data-icon-dish');
        if (iconDish) {
          return $('#cpu-mobile .status-bar .dish-step').html('<span class="' + iconDish + '">&nbsp;</span>');
        }
      };

      ConfiguratorMobile.prototype.removeStep = function() {
        var currentStep, stepToRemove;
        stepToRemove = self.wizard.attr('data-step-remove');
        if (stepToRemove) {
          self.wizard.steps('remove', stepToRemove);
          self.wizard.attr('data-step-remove', '');
        }
        currentStep = self.wizard.steps('getCurrentStep');
        return $('#cpu-mobile .status-bar .section-name').text(currentStep.title);
      };

      ConfiguratorMobile.prototype.restart = function() {
        $('#cpu-mobile-t-0').trigger('click');
        $('#cpu-mobile .status-bar .inner-bar').css({
          'width': '0px'
        });
        self.adaptHeightStep();
        return self.clearDish();
      };

      ConfiguratorMobile.prototype.clearDish = function() {
        $('#piatto-mobile .slice').removeClass('fill');
        $('#piatto-mobile .background-img-dish').remove();
        $('#piatto-mobile .slice input').val('');
        $('#piatto-mobile .slice .text-container').show();
        $('#piatto-mobile button').blur();
        return $('#piatto-mobile .dish-name input').val('');
      };

      ConfiguratorMobile.prototype.onStepChangedFunctions = function() {
        var currentStep, currentStepIndex;
        $('html,body').stop(false, true).animate({
          scrollTop: 0
        }, 'fast');
        currentStep = self.wizard.steps('getCurrentStep');
        currentStepIndex = self.wizard.steps('getCurrentIndex');
        $('#cpu-mobile .status-bar .section-name').text(currentStep.title);
        if (currentStepIndex === 0) {
          $('header').show();
          $('#menu-bar').show();
          $('#cpu-mobile .status-bar').hide();
          $('.wrap-menu-inner').show();
          return $('#configuratore-piatto-unico-mobile .restart-configurator-button').hide();
        } else {
          $('header').hide();
          $('#menu-bar').hide();
          return $('#cpu-mobile .status-bar').show();
        }
      };

      ConfiguratorMobile.prototype.showLoading = function() {
        return $('#mobile-loading').show();
      };

      ConfiguratorMobile.prototype.hideLoading = function() {
        return $('#mobile-loading').hide();
      };

      setCookie = function(name, value) {
        var cookie, date, expires;
        date = new Date();
        date.setTime(date.getTime() + (10 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
        cookie = name + "=" + value + expires + "; path=/";
        document.cookie = cookie;
        return document.cookie;
      };

      getCookie = function(name) {
        var c, ca, i, nameEQ;
        nameEQ = name + "=";
        ca = document.cookie.split(";");
        i = 0;
        while (i < ca.length) {
          c = ca[i];
          while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
          i++;
        }
        return null;
      };

      return ConfiguratorMobile;

    })();
    w.configuratorMobile = new ConfiguratorMobile();
    configuratorMobile.ready();
  })($, jQuery, document, window);

}).call(this);
